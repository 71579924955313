export const colors = {
  primary: "#000000",
  secondary: "#efefef",
  Accent: "#fafafa",
  background: "#ffffff",
  highlight: "#000000",
};

export const customTheme = {
  tooltip: {
    target: "w-fit flex items-center",
    animation: "transition-opacity",
    arrow: {
      base: "absolute z-10 h-3 w-3 rotate-45",
      style: {
        dark: "bg-gray-900 dark:bg-gray-700",
        light: "bg-grey-500",
        auto: "bg-white dark:bg-gray-700",
      },
      placement: "-4px",
    },
    base: "absolute z-10 inline-block rounded-lg px-3 py-2 text-sm font-semibold shadow-sm",
    hidden: "invisible opacity-0",
    style: {
      dark: "bg-gray-900 text-white dark:bg-gray-700",
      light: "bg-grey-500 text-gray-900",
      auto: "border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white",
    },
    content: "relative z-20",
  },
};

export const presetSampleData = [
  {
    preset_name: "Preset 1",
    data: [
      {
        instrumentId: 0,
        initialNotes: "0001000100010001",
      },
    ],
  },
  {
    preset_name: "Preset 2",
    data: [
      {
        instrumentId: 5,
        initialNotes: "0001000100010001",
        initialVolume: -30,
      },
      {
        instrumentId: 1,
        initialNotes: "0010001000100010",
        initialVolume: -20,
      },
      {
        instrumentId: 2,
        initialNotes: "0100010001000100",
        initialVolume: 0,
      },
      {
        instrumentId: 6,
        initialNotes: "1000100010001000",
        initialVolume: -2,
      },
      {
        instrumentId: 0,
        initialNotes: "0000111100000000",
        initialVolume: -30,
      },
    ],
  },
];

export const sampleInstruments = [
  {
    name: "clap",
    displayName: "Clap",
    kit: "808",
    url: "/audios/instruments/clap.wav",
  },
  {
    name: "kick",
    displayName: "Kick",
    kit: "808",
    url: "/audios/instruments/kick.wav",
  },
  {
    name: "closed-hihat",
    displayName: "HiHat 1",
    kit: "808",
    url: "/audios/instruments/hihat.wav",
  },
  {
    name: "open-hihat",
    displayName: "HiHat 2",
    kit: "808",
    url: "/audios/instruments/open-hihat.wav",
  },
  {
    name: "bass",
    displayName: "Bass",
    kit: "808",
    url: "/audios/instruments/bass.wav",
  },
  {
    name: "snare",
    displayName: "Snare",
    kit: "808",
    url: "/audios/instruments/snare.wav",
  },
  {
    name: "tom",
    displayName: "Tom",
    kit: "808",
    url: "/audios/instruments/tom.wav",
  },
  {
    name: "clave",
    displayName: "Clave",
    kit: "808",
    url: "/audios/instruments/clave.wav",
  },
  {
    name: "cowbell",
    displayName: "Cowbell",
    kit: "808",
    url: "/audios/instruments/cowbell.wav",
  },
  {
    name: "maracas",
    displayName: "Maracas",
    kit: "808",
    url: "/audios/instruments/maracas.wav",
  },
  {
    name: "rimshot",
    displayName: "Rimshot",
    kit: "808",
    url: "/audios/instruments/rim.wav",
  },
];
