import React from "react";
import Button from "../Button/Button";
import logo from "../../assets/images/logo_mid_dark.png"
import { IconX } from "@tabler/icons-react";

const AboutContainer = ({ isVisible, children, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex justify-center items-center p-4 z-20">
      <div className="flex z-10 flex-col bg-white rounded-lg shadow-lg p-6 w-full max-w-[600px]">
        <div className="flex-1">{children}</div>
        <div className="flex self-end justify-between items-end w-full h-24">
          <img src={logo} className="h-6" />
          <Button onClick={onClose}>
            <IconX size={20} />
          </Button>
        </div>
      </div>
      <div className="fixed inset-0 z-0 bg-grey-100 opacity-70"></div>
    </div>
  );
};

export default AboutContainer;
