import React from "react";
import { IconArrowsMaximize, IconArrowsMinimize } from "@tabler/icons-react";
import { Tooltip } from "flowbite-react";

import Switch from "../Switch/Switch";
import Button from "../Button/Button";
import ToggleSwitch from "../Switch/ToggleSwitch";

import { useFullScreen } from "../../hooks/custom-hooks";

export default function Header({
  label = "",
  turnOnSwitch,
  onSwitch,
  toggleSwitchValue,
  onToggleSwitch,
}) {
  const { isFullScreen, toggleFullScreen } = useFullScreen();

  return (
    <div className="bg-gradient-to-b from-grey-400 to-white flex justify-between items-center px-4 py-3 rounded-xl">
      <div className="">
        <div className="flex items-center gap-3">
          <h1 className="text-lg max-w-[140px] lg:max-w-none overflow-hidden font-bold">{label}</h1>
          {onSwitch && (
            <Tooltip
              placement="bottom"
              style="light"
              content={turnOnSwitch ? "Enabled" : "Disabled"}
            >
              <Switch checked={turnOnSwitch} onChange={onSwitch} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {onToggleSwitch && (
          <ToggleSwitch
            isChecked={toggleSwitchValue}
            handleToggle={onToggleSwitch}
          />
        )}
        <Tooltip
          placement="bottom-end"
          className={`${isFullScreen ? "w-[130px]" : "w-[100px]"} text-right`}
          style="light"
          content={isFullScreen ? "Exit Full Screen" : "Full Screen"}
        >
          <Button onClick={toggleFullScreen}>
            {isFullScreen ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
