import { WEQ8Runtime } from "weq8";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
import SavePEQFilterModal from "../../components/SavePEQFilterModal/SavePEQFilterModal";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";

import { getPEQPlayerData } from "../../services/players";
import "weq8/ui";

export default function PEQPlayer() {
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
  const { id } = useParams();

  //Player Audio variables
  const [listAudio, setListAudio] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1.0);
  const [duration, setDuration] = useState("00:00");
  const [isEQGraphOpen, setEQGraphOpen] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [filter, setFilter] = useState(-1);
  const [byPass, setByPass] = useState(false);
  const [weq8ref, setWeq8] = useState();

  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaElementSourceRef = useRef(null);

  const toggleAbout = () => setIsAboutVisible(!isAboutVisible);
  const visualisationNode = document
    .querySelector("weq8-ui")
    ?.shadowRoot.querySelector(".visualisation");
  const filterNode = document
    .querySelector("weq8-ui")
    ?.shadowRoot.querySelector(".filters");
  const selectedTouchNode = visualisationNode?.querySelectorAll(
    ".filter-handle-positioner > .filter-handle"
  );

  const selectedRowhNode = filterNode?.querySelectorAll("weq8-ui-filter-row");

  const selectedTouchIndex = Array.from(selectedTouchNode ?? []).findIndex(
    (element) => element?.classList.contains("selected")
  );
  const selectedRowIndex = Array.from(selectedRowhNode ?? []).findIndex(
    (element) => element?.classList.contains("selected")
  );

  //  console.log(selectedRowIndex);
  //Load Data on first page load
  const fetchData = async (id) => {
    const { data } = await getPEQPlayerData(id);

    setListAudio(data.media_files);
  };

  useEffect(() => {
    //Fetch Audio List
    fetchData(id);
  }, [id]);

  useEffect(() => {
    async function loadFirstAudio() {
      if (listAudio.length > 0) {
        await loadNewTrack(0);
        setIsPlaying(false);
      }
    }

    loadFirstAudio();
  }, [listAudio]);

  const copyCanvas = () => {
    const sourceCanvas = document
      .querySelector("weq8-ui")
      .shadowRoot.querySelector(".visualisation")
      .getElementsByClassName("analyser")[0];
    const destCanvas = document.getElementsByClassName("copy")[0];

    const canvasWidth = sourceCanvas.width;
    const canvasHeight = sourceCanvas.height;

    destCanvas.setAttribute("width", canvasWidth);
    destCanvas.setAttribute("height", canvasHeight);

    const destCanvasContext = destCanvas.getContext("2d");
    destCanvasContext.clearRect(0, 0, destCanvas.width, destCanvas.height);
    destCanvasContext.drawImage(sourceCanvas, 0, 0);
  };

  const togglePlayback = () => {
    if (!audioContextRef || !audioContextRef.current) {
      initAudioContext();
    }
    if (audioPlayerRef && currentAudioIndex > -1) {
      if (!isPlaying) {
        audioPlayerRef.current.play();
        setIsPlaying(true);
      } else {
        audioPlayerRef.current.pause();
        setIsPlaying(false);
        if (isEQGraphOpen) copyCanvas();
      }
    } else {
      if (currentAudioIndex === -1) {
        loadNewTrack(0);
      }
    }
  };
  const handleVolumeChange = (value) => {
    setVolume(Number(value));
    audioPlayerRef.current.volume = Number(value);
  };
  const toggleEQ = () => {
    if (isEQGraphOpen && visualisationNode) {
      visualisationNode.style.visibility = "hidden";
      filterNode.style.display = "grid";
    } else {
      visualisationNode.style.visibility = "visible";
      filterNode.style.display = "none";
    }

    setEQGraphOpen(!isEQGraphOpen);
  };

  //Load new Audio file
  const loadNewTrack = async (index) => {
    setCurrentAudioIndex(index);
    setCurrentTime(0);
    setIsPlaying(false);

    const newSrc = `/audios/${listAudio[index].name}`;

    const audioElem = audioPlayerRef.current;
    audioElem.src = newSrc;
    audioElem.load();
  };

  const initAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext();
    }
    const audioContext = audioContextRef.current;

    let weq8 = weq8ref;
    if (!weq8) {
      weq8 = new WEQ8Runtime(audioContext);
      setWeq8(weq8);
    }

    if (!mediaElementSourceRef.current && audioPlayerRef.current) {
      mediaElementSourceRef.current = audioContext.createMediaElementSource(
        audioPlayerRef.current
      );

      mediaElementSourceRef.current.connect(weq8.input);
      weq8.connect(audioContext.destination);
      document.querySelector("weq8-ui").runtime = weq8;
    }
  };

  // Listen for AudioPlayerRef Events
  useEffect(() => {
    const audioElem = audioPlayerRef.current;
    audioElem.volume = volume;

    const handleTimeUpdate = () => {
      setCurrentTime(audioElem.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(audioElem.duration);
    };

    const handlePlaybackFinished = () => {
      console.log("Playback finished");
      setIsPlaying(false);
    };

    if (audioElem) {
      audioElem.addEventListener("timeupdate", handleTimeUpdate);
      audioElem.addEventListener("loadedmetadata", handleLoadedMetadata);
      audioElem.addEventListener("ended", handlePlaybackFinished);

      // Cleanup
      return () => {
        audioElem.removeEventListener("timeupdate", handleTimeUpdate);
        audioElem.removeEventListener("loadedmetadata", handleLoadedMetadata);
        audioElem.removeEventListener("ended", handlePlaybackFinished);
      };
    }
  }, [volume]);

  const handleAudioLoad = () => {
    console.log("Audio loaded!");
  };

  const handleSeekClick = (value) => {
    console.log("Seek TO", value);
    audioPlayerRef.current.currentTime = value;
    if (isPlaying) {
      audioPlayerRef.current.play();
    }
  };
  const handleCanPlay = () => {
    setPlayerReady(true);
    //    initAudioContext();
  };

  useEffect(() => {
    if (!weq8ref) return;

    if (filter === 0) {
      weq8ref.setFilterFrequency(0, 180);
      weq8ref.setFilterFrequency(1, 1000);
      weq8ref.setFilterFrequency(2, 3000);
      weq8ref.setFilterFrequency(3, 2000);
      weq8ref.setFilterGain(0, 10);
      weq8ref.setFilterGain(1, 5);
      weq8ref.setFilterGain(2, 3);
      weq8ref.setFilterGain(3, 10);
    }
    if (filter === 1) {
      weq8ref.setFilterFrequency(0, 580);
      weq8ref.setFilterFrequency(1, 1000);
      weq8ref.setFilterFrequency(2, 5000);
      weq8ref.setFilterFrequency(3, 8000);
      weq8ref.setFilterGain(0, 10);
      weq8ref.setFilterGain(1, 5);
      weq8ref.setFilterGain(2, 3);
      weq8ref.setFilterGain(3, 10);
    }
    if (filter === 2) {
      weq8ref.setFilterFrequency(0, 180);
      weq8ref.setFilterFrequency(1, 500);
      weq8ref.setFilterFrequency(2, 1000);
      weq8ref.setFilterFrequency(3, 2500);
      weq8ref.setFilterGain(0, -10);
      weq8ref.setFilterGain(1, -5);
      weq8ref.setFilterGain(2, -3);
      weq8ref.setFilterGain(3, 10);
    }
  }, [filter]);

  useEffect(() => {
    if (!weq8ref) return;
    if (isEQGraphOpen) {
      weq8ref.spec.map((item, index) => {
        weq8ref.setFilterFrequency(index, item.frequency);
        weq8ref.setFilterGain(index, item.gain);
      });
    }
  }, [isEQGraphOpen]);

  useEffect(() => {
    if (!weq8ref) return;

    Array(8)
      .fill()
      .map((item, index) => {
        weq8ref.toggleBypass(index, byPass);
      });

    setTimeout(() => {
      fillCustomStyleOnGraph();
    }, 100);
  }, [byPass, weq8ref]);

  const fillCustomStyleOnGraph = () => {
    if (visualisationNode) {
      const elements =
        visualisationNode.getElementsByClassName("filter-handle");
      [...elements].forEach((element) => {
        element.style.backgroundColor = element.classList.contains("bypassed")
          ? "#FF5047"
          : element.classList.contains("selected")
          ? "#FFD600"
          : "#00CC8F";

        element.style.color = "white";
      });
    }
  };

  const fillCustomStyleOnTable = () => {
    filterNode.style.color = "black";
    filterNode.style.fontWeight = "bold";
    filterNode.querySelector("thead").style.gap = "10px";
    Array.from(filterNode.querySelectorAll("th")).forEach((item) => {
      item.style.border = "none";
      item.style.fontWeight = "bold";
    });
    Array.from(filterNode.querySelectorAll("weq8-ui-filter-row")).forEach(
      (item) => {
        const selected = item.classList.contains("selected");

        item.style.gap = "10px";
        item.style.border = "none";
        item.style.fontWeight = "bold";
        item.style.borderRadius = 0;
        item.style.padding = "2px 10px 2px 10px";

        if (!selected) {
          item.style.color = "black";
        } else {
          item.style.color = "white";
        }

        const rowContent = item.shadowRoot;
        const chipContent = rowContent.querySelector(".chip");
        const numberContent = rowContent.querySelector(".filterNumber");

        if (rowContent.querySelector(".filterNumber")) {
          if (chipContent.classList.contains("bypassed")) {
            numberContent.style.backgroundColor = "#FF5047";
          } else if (!chipContent.classList.contains("disabled")) {
            numberContent.style.backgroundColor = "#00CC8F";
            numberContent.style.color = "#fff";
          }

          if (selected) {
            numberContent.style.backgroundColor = "#FFD600";
          }

          Array.from(rowContent.querySelectorAll("input")).forEach(
            (inputNode) => {
              if (!inputNode.disabled) {
                if (selected) {
                  inputNode.style.color = "#fff";
                } else {
                  inputNode.style.color = "#000";
                }
                inputNode.style.fontWeight = "bold";
              }
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    if (visualisationNode && isEQGraphOpen) {
      fillCustomStyleOnGraph();
    }
    if (filterNode && !isEQGraphOpen) {
      console.log("ha", selectedRowIndex);
      fillCustomStyleOnTable();
    }
  }, [
    isEQGraphOpen,
    isPlaying,
    selectedRowIndex,
    selectedTouchIndex,
    filter,
    filterNode,
  ]);

  useEffect(() => {
    if (visualisationNode) {
      visualisationNode.style.backgroundColor = "#a8a8a8";
      visualisationNode.style.visibility = "hidden";
    }
  }, [visualisationNode]);

  return (
    <div className="relative">
      <audio
        loop
        ref={audioPlayerRef}
        controls={false}
        onLoadedData={handleAudioLoad}
        onCanPlay={handleCanPlay}
        className="hidden w-full"
      >
        <source src="" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <PlayerContainer>
        <Header
          label="Parametric EQ"
          turnOnSwitch={!byPass}
          onSwitch={() => setByPass(!byPass)}
          toggleSwitchValue={isEQGraphOpen}
          onToggleSwitch={toggleEQ}
        />
        <div className="flex flex-col h-full overflow-auto">
          <div className="flex flex-col md:flex-row justify-between pt-4 px-2">
            <div className="flex flex-col md:flex-row items-start md:items-center gap-1 md:gap-4">
              <div className="flex gap-4 self-end">
                <Button
                  onClick={() => setShowSaveFilterModal(!showSaveFilterModal)}
                  className={`${
                    showSaveFilterModal
                      ? "bg-gray-900 text-white"
                      : "text-black"
                  } p-2 hidden rounded-full`}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full h-full p-2">
            <div className="relative">
              <weq8-ui
                class={`eq-filter ${
                  isEQGraphOpen
                    ? "bg-grey-400 rounded-none p-0"
                    : "bg-transparent"
                }`}
              />
              {isEQGraphOpen && (
                <div
                  className={`hidden ${
                    isPlaying ? "invisible" : "visible"
                  } absolute xl:left-[233px] bottom-0 max-h-[300px] overflow-hidden`}
                >
                  <canvas
                    width="1"
                    height="1"
                    className="copy max-w-full max-h-[300px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <AudioPlayerController
          isAboutVisible={isAboutVisible}
          audioIsPlaying={isPlaying}
          currentTime={currentTime}
          volume={volume}
          duration={duration}
          onPlayClick={togglePlayback}
          onVolumeChange={handleVolumeChange}
          onSeekTo={handleSeekClick}
          onAboutClick={toggleAbout}
          listAudio={listAudio}
          currentAudioIndex={currentAudioIndex}
          handleAudioItem={loadNewTrack}
          listPreset={[
            { name: "Filter Presets 1", value: "1" },
            { name: "Filter Presets 2", value: "2" },
            { name: "Filter Presets 3", value: "3" },
          ]}
          currentPresetIndex={filter}
          handlePresetItem={setFilter}
        />
      </PlayerContainer>
      <AboutContainer isVisible={isAboutVisible} onClose={toggleAbout}>
        <h2 className="text-2xl font-bold mb-4">PEQ Player</h2>
        <p className="mb-4">
          We'd love to hear any suggestions you have about how to improve this
          player. To make a suggestion, click here.
        </p>
        <p className="mb-4">Keep humming!</p>
      </AboutContainer>
      <SavePEQFilterModal
        data={weq8ref?.spec}
        open={showSaveFilterModal}
        onClose={() => setShowSaveFilterModal(false)}
      />
    </div>
  );
}
