import React, { useState } from "react";
import {
  IconChartCandle,
  IconChevronLeft,
  IconChevronRight,
  IconMenu2,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlaylist,
  IconVolume,
} from "@tabler/icons-react";
import { Tooltip } from "flowbite-react";

import VerticalSlider from "../Slider/VerticalSlider";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";

export default function AudioPlayerController({
  isAboutVisible,
  audioIsPlaying,
  currentTime,
  volume,
  duration,
  listAudio,
  currentAudioIndex,
  handleAudioItem,
  listPreset,
  currentPresetIndex,
  handlePresetItem,
  onPlayClick,
  onVolumeChange,
  onPrevClick,
  onNextClick,
  onSeekTo,
  onAboutClick,
}) {
  const [isVolumeSliderVisible, setVolumeSliderVisibility] = useState(false);
  const toggleVolumeSlider = () => {
    setVolumeSliderVisibility(!isVolumeSliderVisible);
  };
  const getMinutes = (t) => {
    // setCurrentDuration(t);
    // setCurrentTime(audio.currentTime);
    const min = parseInt(t / 60);
    const sec = parseInt(t % 60);
    return `${"0" + min}:${sec < 10 ? "0" + sec : sec}`;
  };

  return (
    <>
      <div className="bg-white w-full h-fit py-2 self-end rounded-xl flex flex-col">
        <div className="flex-1 flex justify-between px-2">
          <div className="flex h-full rounded-md bg-grey-600 py-1 px-1 gap-1">
            <Tooltip style="light" content={audioIsPlaying ? "Pause" : "Play"}>
              <Button onClick={onPlayClick}>
                {audioIsPlaying ? (
                  <IconPlayerPauseFilled className="w-6 h-6" />
                ) : (
                  <IconPlayerPlayFilled className="w-6 h-6" />
                )}
              </Button>
            </Tooltip>
            <div className="relative">
              <Tooltip style="light" content="Volume">
                <Button onClick={toggleVolumeSlider}>
                  <IconVolume className="w-6 h-6" />
                </Button>
              </Tooltip>
              {isVolumeSliderVisible && (
                <VerticalSlider
                  className="-top-14 left-5 w-[100px] p-3 rounded-md border border-grey-400 bg-white"
                  min="0"
                  max="1"
                  step="0.01"
                  defaultValue={volume}
                  onChange={(e) => onVolumeChange(e.target.value)}
                />
              )}
            </div>
            {onPrevClick && (
              <Tooltip content="Previous" style="light">
                <Button onClick={onPrevClick}>
                  <IconChevronLeft />
                </Button>
              </Tooltip>
            )}
            {onNextClick && (
              <Tooltip content="Next" style="light">
                <Button onClick={onNextClick}>
                  <IconChevronRight />
                </Button>
              </Tooltip>
            )}
          </div>
          <div className="flex items-center rounded-full px-2 gap-2">
            {listAudio && (
              <Tooltip content="Audio" style="light">
                <Dropdown
                  placeholder="Select Audio"
                  options={listAudio}
                  currentIndex={currentAudioIndex}
                  handleOptionClickEvent={handleAudioItem}
                  mobileIcon={<IconPlaylist />}
                />
              </Tooltip>
            )}
            {listPreset && (
              <Tooltip content="Preset" style="light">
                <Dropdown
                  placeholder="Select Preset"
                  options={listPreset}
                  currentIndex={currentPresetIndex}
                  handleOptionClickEvent={handlePresetItem}
                  mobileIcon={<IconChartCandle />}
                />
              </Tooltip>
            )}
            <Tooltip content="Menu" style="light">
              <Button
                className={`p-3 bg-grey-600 ${
                  isAboutVisible ? "z-50" : "z-10"
                }`}
                onClick={onAboutClick}
              >
                <IconMenu2 />
              </Button>
            </Tooltip>
          </div>
        </div>
        {onSeekTo && (
          <div className="flex-1 h-full flex justify-between px-2 mt-1 items-center gap-2">
            <div className="hidden md:block">
              <span>{getMinutes(currentTime)}</span>
            </div>
            <div className="w-full">
              <input
                type="range"
                min={0}
                max={duration}
                step={duration / 10000}
                value={currentTime}
                onChange={(e) => onSeekTo(e.target.value)}
                className="volume-slider w-full h-3 rounded-lg overflow-hidden appearance-none bg-gray-400"
              />
            </div>
            <div className="hidden md:block">
              <span>{getMinutes(duration)}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
