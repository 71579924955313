import React, { useState } from "react";
import Button from "../Button/Button";

export default function SavePEQFilterModal({ open, data = [], onClose }) {
  const [filterName, setFilterName] = useState("");
  if (!open) return <div />;

  return (
    <div className="p-2 absolute top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div className="max-w-[500px]  border border-gray-500 rounded-xl  flex flex-col bg-white shadow-lg p-6 w-full">
        <h2 className="text-2xl font-bold mb-4">Save Filter Presets</h2>
        <input
          className="rounded-lg p-1 px-3 border-gray-500 border focus:border-gray-900 outline-none"
          value={filterName}
          placeholder="Your Filter Preset Name"
          onChange={(e) => setFilterName(e.target.value)}
        />
        {data.length === 0 ? (
          <div />
        ) : (
          <div className="mt-3 flex flex-col items-center">
            <div className="mb-0.5 flex gap-2 text-right text-sm font-bold">
              <div className="w-5 mr-2">No.</div>
              <div className="w-[100px] text-left uppercase">Filter</div>
              <div className="w-[70px]">Frequency</div>
              <div className="w-[50px]">Gain</div>
              <div className="w-10">Q</div>
              <div className="w-[60px]">ByPass</div>
            </div>
            {data.map((item, index) => (
              <div className="flex gap-2 text-right text-sm">
                <div className="w-5 mr-2">{index + 1}.</div>
                <div className="w-[100px] text-left uppercase">{item.type}</div>
                <div className="w-[70px]">
                  {Number(item.frequency).toFixed(0)}Hz
                </div>
                <div className="w-[50px]">{Number(item.gain).toFixed(1)}dB</div>
                <div className="w-10">{Number(item.Q).toFixed(2)}</div>
                <div className="w-[60px]">{item.bypass ? "True" : "False"}</div>
              </div>
            ))}
          </div>
        )}
        <div className="flex mt-4 gap-4 justify-end">
          <Button
            onClick={() => onClose()}
            className="font-bold px-4 py-2 border-gray-500 rounded-lg border"
          >
            Cancel
          </Button>
          <Button
            disabled
            onClick={() => onClose()}
            className="disabled:text-gray-400 font-bold px-4 py-2  border-gray-500 rounded-lg border"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
