import { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import VerticalLineAnimator from "./VerticalLineAnimator";

const WaveCanvas = ({ waveform, color, progress }) => {
	const canvasRef = useRef();
	const svgRef = useRef(null);
	const offsetX = 50;
	const [currentTime, setCurrentTime] = useState(0);

	function debounce(fn, delay) {
		let timeoutId;
		return function (...args) {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(() => {
				fn(...args);
			}, delay);
		};
	}

	useEffect(() => {
		const draw = () => {
			const width = svgRef.current.offsetWidth;
			// console.log("resize canvas width: ", width);

			const container = d3.select(svgRef.current);
			const channel = waveform.channel(0);

			const min = channel.min_array();
			const max = channel.max_array();

			const x = d3
				.scaleLinear()
				.domain([0, waveform.length])
				.rangeRound([0, width]);

			const y = d3
				.scaleLinear()
				.domain([d3.min(min), d3.max(max)])
				.rangeRound([offsetX, -offsetX]);

			const area = d3
				.area()
				.x((_, i) => x(i))
				.y0((_, i) => y(min[i]))
				.y1(y);

			container.select("svg").remove(); // Clear previous SVG

			const graph = container
				.append("svg")
				.attr("width", width)
				.attr("height", "100")
				.datum(max)
				.append("path")
				.attr("transform", `translate(0, ${offsetX})`)
				.attr("d", area)
				.attr("fill", color)
				.attr("stroke", color);
		};

		const handleResize = (element) => {
			// console.log("Resized element:", element);
			// console.log(
			// 	"New size:",
			// 	element.getBoundingClientRect().width,
			// 	"x",
			// 	element.getBoundingClientRect().height
			// );

			// Add additional functionality as needed
			draw();
		};

		// Debounce the handleResize function
		const debouncedHandleResize = debounce(handleResize, 300); // 300 ms delay

		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				debouncedHandleResize(entry.target);
			}
		});

		if (waveform) {
			if (svgRef.current) {
				resizeObserver.observe(svgRef.current);
			}
		}

		return () => {
			if (svgRef.current) {
				resizeObserver.unobserve(svgRef.current);
			}
		};
	}, [waveform, color]);


	return (
		<>
			<div
				ref={svgRef}
				id="waveform-container"
				className="relative w-full h-[100px] overflow-hidden"
			>
				<VerticalLineAnimator position={progress}/>
				
			</div>
		</>
	);
};

export default WaveCanvas;
