import { useState, useRef, useEffect } from "react";
import * as Tone from "tone";
import Dial from "../Dial/Dial";

export default function EffectLayer({
  effectType,
  effectConfig,
  audioRef,
  mediaElementSourceRef,
  audioContextRef,
  isPlaying,
  turnOnSwitch,
  reloadEffect,
}) {
  const [effectValueConfig, setEffectValueConfig] = useState({});

  const effectRef = useRef(null);

  const handleRangeChange = (effect, value) => {
    console.log(effect, value);
    setEffectValueConfig((prev) => ({
      ...prev,
      [effect]: Number(value),
    }));
    // console.log(effectValueConfig);
    updateEffect();
  };

  const loadEffect = () => {
    if (reloadEffect) {
      if (!audioContextRef.current) {
        audioContextRef.current = new AudioContext();
      }
      const audioContext = audioContextRef.current;

      if (!mediaElementSourceRef.current && audioRef.current) {
        mediaElementSourceRef.current = audioContext.createMediaElementSource(
          audioRef.current
        );
      }
      const sourceNode = mediaElementSourceRef.current;

      // Set the context used by Tone.js
      Tone.setContext(audioContext);

      // Fetch effect Tone
      switch (effectType) {
        case "delay":
          // Create a FeedbackDelay effect
          console.log(effectValueConfig);
          effectRef.current = new Tone.FeedbackDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
          }).toDestination();
          break;
        case "pingPongDelay":
          // Create a pingPongDelay effect
          effectRef.current = new Tone.PingPongDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
          }).toDestination();
          break;
        case "distortion":
          // Create a distortion effect
          effectRef.current = new Tone.Distortion({
            distortion: effectValueConfig.Distortion,
          }).toDestination();
          break;
        case "reverb":
          // Create a reverb effect
          effectRef.current = new Tone.Reverb({
            decay: effectValueConfig.decay,
            preDelay: effectValueConfig.preDelay,
            wet: effectValueConfig.Wet,
          }).toDestination();
          break;
        case "flanger":
          // Create a flanger effect
          effectRef.current = new Tone.Chorus({
            frequency: effectValueConfig.Frequency,
            delayTime: effectValueConfig.Time,
            depth: effectValueConfig.Depth,
            feedback: effectValueConfig.Feedback,
            wet: effectValueConfig.Wet,
          }).toDestination();

          break;
        case "quadrafuzz":
          effectRef.current = createQuadrafuzz(
            effectValueConfig.lowGain,
            effectValueConfig.midLowGain,
            effectValueConfig.midHighGain,
            effectValueConfig.highGain
          );
          break;
        case "dubdelay":
          const filter = new Tone.Filter({
            frequency: effectValueConfig.Cutoff,
            type: "lowpass",
          }).toDestination();

          const delay = new Tone.FeedbackDelay({
            delayTime: effectValueConfig.Time,
            wet: effectValueConfig.Wet,
            feedback: effectValueConfig.Feedback,
            maxDelay: 1.0,
          }).connect(filter);

          effectRef.current = delay;
          break;
        case "compressor":
          // Create a Compressor effect with specified parameters
          effectRef.current = new Tone.Compressor({
            threshold: effectValueConfig.Threshold,
            ratio: effectValueConfig.Ratio,
            attack: effectValueConfig.Attack,
            release: effectValueConfig.Release,
            knee: effectValueConfig.Knee,
          }).toDestination();
          break;
        case "gate":
          // Create a Gate effect with specified parameters
          effectRef.current = new Tone.Gate({
            threshold: effectValueConfig.Threshold,
            attack: effectValueConfig.Attack,
            release: effectValueConfig.Release,
          }).toDestination();
          break;
        case "multibandCompressor":
          // Create a multiBandCompressor effect with specified parameters
          effectRef.current = new Tone.MultibandCompressor({
            low: {
              threshold: effectValueConfig.lowThreshold,
              ratio: effectValueConfig.lowRatio,
              attack: effectValueConfig.lowAttack,
              release: effectValueConfig.lowRelease,
            },
            mid: {
              threshold: effectValueConfig.midThreshold,
              ratio: effectValueConfig.midRatio,
              attack: effectValueConfig.midAttack,
              release: effectValueConfig.midRelease,
            },
            high: {
              threshold: effectValueConfig.highThreshold,
              ratio: effectValueConfig.highRatio,
              attack: effectValueConfig.highAttack,
              release: effectValueConfig.highRelease,
            },
            lowFrequency: effectValueConfig.lowFrequency,
            highFrequency: effectValueConfig.highFrequency,
          }).toDestination();
          break;
        case "limiter":
          // Create a limiter effect with specified parameters
          effectRef.current = new Tone.Limiter({
            threshold: effectValueConfig.threshold,
          }).toDestination();
          break;

        default:
          console.log("Unknown player type:", effectType);
          break;
      }

      if (turnOnSwitch) {
        Tone.connect(sourceNode, effectRef.current?.input);
        Tone.connect(effectRef.current?.output, audioContext.destination);
      } else {
        Tone.disconnect(sourceNode);
        sourceNode.connect(audioContext.destination);
      }

      // return () => {
      //     sourceNode.disconnect();
      // };
    }
  };

  const updateEffect = () => {
    console.log("effect Type", effectType);
    // Fetch effect Tone
    switch (effectType) {
      case "delay":
        // Create a FeedbackDelay effect
        // effectRef.current.set({
        //     delayTime: effectValueConfig.Time,
        //     wet:  effectValueConfig.Wet,
        //     feedback: effectValueConfig.Feedback });
        const audioEffect = effectRef.current;
        audioEffect.delayTime.value = effectValueConfig.Time;
        audioEffect.wet.value = effectValueConfig.Wet;
        audioEffect.feedback.value = effectValueConfig.Feedback;

        break;
      case "pingPongDelay":
        // Create a pingPongDelay effect
        effectRef.current.set({
          delayTime: effectValueConfig.Time,
          wet: effectValueConfig.Wet,
          feedback: effectValueConfig.Feedback,
        });
        break;
      case "distortion":
        // Create a distortion effect
        effectRef.current.set({
          distortion: effectValueConfig.Distortion,
        });
        break;
      case "reverb":
        // Create a reverb effect
        effectRef.current.set({
          decay: effectValueConfig.decay,
          preDelay: effectValueConfig.preDelay,
          wet: effectValueConfig.Wet,
        });
        break;
      case "flanger":
        // Create a flanger effect
        effectRef.current.set({
          frequency: effectValueConfig.Frequency,
          delayTime: effectValueConfig.Time,
          depth: effectValueConfig.Depth,
          feedback: effectValueConfig.Feedback,
          wet: effectValueConfig.Wet,
        });

        break;
      case "quadrafuzz":
        effectRef.current.setGains(
          effectValueConfig.lowGain,
          effectValueConfig.midLowGain,
          effectValueConfig.midHighGain,
          effectValueConfig.highGain
        );
        break;
      case "dubdelay":
        const audioEffectDubDelay = effectRef.current;
        audioEffectDubDelay.delayTime.value = effectValueConfig.Time;
        audioEffectDubDelay.wet.value = effectValueConfig.Wet;
        audioEffectDubDelay.feedback.value = effectValueConfig.Feedback;

        // Assuming filter is connected to delay, access the filter through delay's output
        if (audioEffectDubDelay.filter) {
          audioEffectDubDelay.filter.frequency.value = effectValueConfig.Cutoff;
        }
        break;
      case "compressor":
        // Create a Compressor effect with specified parameters
        effectRef.current.set({
          threshold: effectValueConfig.Threshold,
          ratio: effectValueConfig.Ratio,
          attack: effectValueConfig.Attack,
          release: effectValueConfig.Release,
          knee: effectValueConfig.Knee,
        });
        break;
      case "gate":
        // Create a gate effect with specified parameters
        effectRef.current.set({
          threshold: effectValueConfig.Threshold,
          attack: effectValueConfig.Attack,
          release: effectValueConfig.Release,
        });
        break;
      case "multibandCompressor":
        // Create a multiBandCompressor effect with specified parameters
        effectRef.current.set({
          low: {
            threshold: effectValueConfig.lowThreshold,
            ratio: effectValueConfig.lowRatio,
            attack: effectValueConfig.lowAttack,
            release: effectValueConfig.lowRelease,
          },
          mid: {
            threshold: effectValueConfig.midThreshold,
            ratio: effectValueConfig.midRatio,
            attack: effectValueConfig.midAttack,
            release: effectValueConfig.midRelease,
          },
          high: {
            threshold: effectValueConfig.highThreshold,
            ratio: effectValueConfig.highRatio,
            attack: effectValueConfig.highAttack,
            release: effectValueConfig.highRelease,
          },
          lowFrequency: effectValueConfig.lowFrequency,
          highFrequency: effectValueConfig.highFrequency,
        });
        break;
      case "limiter":
        // Create a limiter effect with specified parameters
        effectRef.current.set({
          threshold: effectValueConfig.threshold,
        });
        break;
      default:
        console.log("Unknown player type:", effectType);
        break;
    }
    // console.log(effectRef.current);
  };
  //Load Effect and Connect with AudioContext
  useEffect(() => {
    console.log("load effect");

    if (reloadEffect) {
      loadEffect();
    }
  }, [reloadEffect, turnOnSwitch]);

  useEffect(() => {
    if (effectConfig) {
      const objKeys = Object.keys(effectConfig);
      objKeys.forEach((key) => {
        setEffectValueConfig((prev) => ({
          ...prev,
          [key]: effectConfig[key].value,
        }));
      });
    }
  }, [effectConfig]);

  const createQuadrafuzz = (lowGain, midLowGain, midHighGain, highGain) => {
    const input = new Tone.Gain();
    const output = new Tone.Gain();

    const lowBand = new Tone.Filter({ frequency: 200, type: "lowpass" });
    const midLowBand = new Tone.Filter({ frequency: 1000, type: "bandpass" });
    const midHighBand = new Tone.Filter({ frequency: 3000, type: "bandpass" });
    const highBand = new Tone.Filter({ frequency: 8000, type: "highpass" });

    const lowDistortion = new Tone.Distortion(lowGain);
    const midLowDistortion = new Tone.Distortion(midLowGain);
    const midHighDistortion = new Tone.Distortion(midHighGain);
    const highDistortion = new Tone.Distortion(highGain);

    lowBand.connect(lowDistortion);
    midLowBand.connect(midLowDistortion);
    midHighBand.connect(midHighDistortion);
    highBand.connect(highDistortion);

    lowDistortion.connect(output);
    midLowDistortion.connect(output);
    midHighDistortion.connect(output);
    highDistortion.connect(output);

    input.fan(lowBand, midLowBand, midHighBand, highBand);
    output.toDestination();

    return {
      input,
      output,
      setGains: (low, midLow, midHigh, high) => {
        lowDistortion.distortion = low;
        midLowDistortion.distortion = midLow;
        midHighDistortion.distortion = midHigh;
        highDistortion.distortion = high;
      },
    };
  };

  return (
    <div className="flex flex-wrap md:flex-row gap-2 md:gap-10 justify-center">
      {effectConfig ? (
        Object.entries(effectConfig).map(([effectName, config]) => (
          <EffectController
            key={effectName}
            params={config}
            handleRangeChange={handleRangeChange}
            effectName={effectName}
          />
        ))
      ) : (
        // Render some placeholder or loading state if typeConfig is null/undefined
        <p>Loading effects configuration...</p>
      )}
    </div>
  );
}

export const EffectController = ({ params, handleRangeChange, effectName }) => {
  const [value, setValue] = useState(params.value || 0);
  const onChange = (value) => {
    const newValue = value;
    setValue(newValue);
    handleRangeChange(effectName, newValue);
  };

  return (
    <div className="flex p-2">
      <Dial
        label={effectName}
        size={80}
        degrees={260}
        min={params.min ?? 0}
        max={params.max ?? 100}
        step={params.step ?? 1}
        value={value ?? 0}
        color={true}
        onChange={onChange}
      />
    </div>
  );
};
