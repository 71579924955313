import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import PlayerContainer from "../../components/PlayerContainer/PlayerContainer";
import AboutContainer from "../../components/AboutContainer/AboutContainer";
import AudioPlayerController from "../../components/AudioPlayerController/AudioPlayerController";
// import AudioWaveTrack from "../../components/AudioWaveTrack/AudioWaveTrack";
import NewAudioWaveTrack from "../../components/AudioWaveTrack/NewAudioWaveTrack";
import AudioSpectrum from "../../components/AudioSpectrum/AudioSpectrum";

import { getAudioCompareData } from "../../services/players";
import Header from "../../components/Header/Header";

export default function AudioCompare() {
  const { id } = useParams();

  //Player Audio variables
  const [listAudio, setListAudio] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
  const [currentAudioURL, setCurrentAudioURL] = useState("");

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [lastTime, setLastTime] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isEQGraphOpen, setEQGraphOpen] = useState(false);
  const [playerReady, setPlayerReady] = useState(false);
  const [isAboutVisible, setIsAboutVisible] = useState(false);
  const [savedTime, setSavedTime] = useState(0);
  const [isContinuity, setIsContinuity] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [onLoop, setOnLoop] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);

  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaElementSourceRef = useRef(null);

  const toggleAbout = () => setIsAboutVisible(!isAboutVisible);

  //Load Data on first page load
  const initialSetup = async (id) => {
    const { data } = await getAudioCompareData(id);
    setListAudio(data[0].audio_files);

    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext();
    }
    const audioContext = audioContextRef.current;
    if (!mediaElementSourceRef.current && audioPlayerRef.current) {
      mediaElementSourceRef.current = audioContext.createMediaElementSource(
        audioPlayerRef.current
      );
      mediaElementSourceRef.current.connect(audioContext.destination);
    }
  };

  useEffect(() => {
    //Fetch Audio List
    initialSetup(id);
  }, [id]);

  const handleVolumeChange = (value) => {
    setVolume(Number(value));
    audioPlayerRef.current.volume = Number(value);
  };
  const toggleEQ = () => {
    setEQGraphOpen(!isEQGraphOpen);
  };
  const handleItemClick = (clickedIndex) => {
    // console.log("handleItemClick", clickedIndex)
    // setIsContinuity(false)
    if (clickedIndex === currentAudioIndex) {
      togglePlayback();
    } else {
      setOnLoop(false);
      setSavedTime(currentTime);
      setIsContinuity(true);

      loadNewTrack(clickedIndex);
      // setCurrentAudioIndex(clickedIndex)
    }
  };
  //Load new Audio file
  const loadNewTrack = (index) => {
    console.log("Loaded Player with Track-#" + index);
    if (index < listAudio.length) {
      setCurrentAudioIndex(index);
      setCurrentAudioURL(listAudio[index].filename);
      setOnLoop(true);

      const audioElem = audioPlayerRef.current;
      audioElem.src = `/audios/${listAudio[index].filename}`;
      audioElem.load();
      setIsPlaying(true);
    }
  };

  //Toggle current Audio file
  const togglePlayback = async () => {
    if (!audioPlayerRef.current || currentAudioIndex < 0) {
      if (currentAudioIndex === -1) {
        loadNewTrack(0);
      }
      return;
    }

    try {
      // Resume the audio context if needed
      await audioContextRef.current.resume();

      if (audioPlayerRef.current.paused) {
        await audioPlayerRef.current.play();
        setIsPlaying(true);
        setWasPlaying(false);
        console.log("play", audioPlayerRef.current);
      } else {
        audioPlayerRef.current.pause();
        setIsPlaying(false);
        setWasPlaying(true);
      }
    } catch (error) {
      console.error("Playback error:", error);
    }
  };
  const handlePlayFinished = () => {
    console.log("Playback finished");
    setIsPlaying(false);

    setSavedTime(currentTime);

    // Code for looping track after complete
    // togglePlayback();

    // Code for going to next track after current track complete
    // if(currentAudioIndex < listAudio.length){
    //     const newIndex = currentAudioIndex + 1;
    //     loadNewTrack(newIndex);
    // }
  };
  const handleTimeUpdate = () => {
    setCurrentTime(audioPlayerRef.current.currentTime);
  };
  const handleLoadedMetadata = () => {
    setDuration(audioPlayerRef.current.duration);
    // audioPlayerRef.current.currentTime = currentTime
  };
  const handleAudioLoad = () => {
    console.log("Audio loaded!");
    if (isContinuity) {
      setCurrentTime(savedTime);
      audioPlayerRef.current.currentTime = savedTime;
    }
    setPlayerReady(true);
  };
  const handleSeekClick = (value) => {
    console.log("Seek TO", value);
    setIsSeeking(true);
    audioPlayerRef.current.pause(); // Pause audio when dragging starts
    setCurrentTime(value);
    // audioPlayerRef.current.pause();
    // setIsPlaying(false)
    //audioPlayerRef.current.currentTime = value;
    // if(isPlaying){
    //     audioPlayerRef.current.play();
    // }
  };

  //Add event listeners for mouseup and mousedown to control audio playback
  useEffect(() => {
    const handleMouseUp = () => {
      if (isSeeking) {
        audioPlayerRef.current.currentTime = currentTime;
        if (wasPlaying) {
          setIsSeeking(true); // Resume audio when dragging ends
        } else{
          setIsSeeking(false);
        }
      }
    };

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isPlaying, currentTime, isSeeking]);

  const handleNextBtn = () => {
    // console.log('Playback Next media');
    if (currentAudioIndex < listAudio.length) {
      const newIndex = currentAudioIndex + 1;
      // setOnLoop(false)
      // setSavedTime(currentTime)
      // setIsContinuity(true)
      setIsContinuity(false);
      setIsPlaying(false);

      loadNewTrack(newIndex);
    }
  };
  const handlePrevBtn = () => {
    // console.log('Playback Prev media');
    if (currentAudioIndex > 0) {
      const newIndex = currentAudioIndex - 1;
      // setOnLoop(false)

      setIsContinuity(false);
      setIsPlaying(false);

      loadNewTrack(newIndex);
    }
  };

  const handleCanPlay = () => {
    console.log("handleCanPlay");
    // if(isContinuity){
    //     // console.log("isPlaying", isPlaying)
    //     if(!isPlaying){
    //         togglePlayback()
    //     }
    // }
    // else{
    //     console.log("isPlaying", isPlaying)
    //     togglePlayback()
    // }

    // setIsPlaying(false);
    if (isSeeking) {
      setIsSeeking(false);
      return;
    }
    if (onLoop) {
      // if(isSeeking){
      //     setIsSeeking(false);
      //     audioPlayerRef.current.play();
      //     setSavedTime(currentTime)
      //     return;
      // }
      audioPlayerRef.current.pause();
      audioPlayerRef.current.play();
      setSavedTime(currentTime);
    } else {
      if (!isPlaying) {
        togglePlayback();
      }
    }
  };

  return (
    <>
      <audio
        ref={audioPlayerRef}
        controls={false}
        loop={true}
        className="hidden w-full"
        onEnded={handlePlayFinished}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onCanPlay={handleCanPlay}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onLoadedData={handleAudioLoad}
      >
        <source src="" type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <PlayerContainer isFullScreen={isFullScreen}>
        <Header
          label="Audio Compare"
          toggleSwitchValue={isEQGraphOpen}
          onToggleSwitch={toggleEQ}
        />
        <AboutContainer isVisible={isAboutVisible} onClose={toggleAbout}>
          <h2 className="text-2xl font-bold mb-4">Audio Compare</h2>
          <p className="mb-4">
            The Audio Compare player is a critical listening and audio
            comparison tool.
          </p>
          <p className="mb-4">
            With this player, you can compare multiple audio clips to hear the
            differences between each.You can also toggle the visual EQ to see
            the frequency response of each clip.
          </p>
          <p className="mb-4">
            We'd love to hear any suggestions you have about how to improve this
            player. To make a suggestion, click here.
          </p>
          <p className="mb-4">Keep humming!</p>
        </AboutContainer>
        <div className="bg-white w-full h-full px-4 flex flex-col overflow-auto">
          {listAudio &&
            listAudio.map((audio, index) => {
              const iseven = index % 2 === 0;
              // console.log(audio)
              return (
                <div key={index}>
                  {/* <AudioWaveTrack
                    audio={audio}
                    audioPlayerRef={audioPlayerRef}
                    trackIndex={index}
                    currentTrackIndex={currentAudioIndex}
                    isPlaying={isPlaying && currentAudioIndex === index}
                    wavePosition={currentTime}
                    duration={duration}
                    onClickHandler={handleItemClick}
                  > */}
                  <NewAudioWaveTrack
                    audio={audio}
                    // audioPlayerRef={audioPlayerRef}
                    trackIndex={index}
                    currentTrackIndex={currentAudioIndex}
                    wavePosition={(currentTime / duration) * 100}
                    // isPlaying={isPlaying && currentAudioIndex === index}
                   // wavePosition={(audioPlayerRef.current.currentTime / duration) * 100}
                    // duration={duration}
                    onClickHandler={handleItemClick}
                  >
                    {index === currentAudioIndex && playerReady && (
                      <div className={isEQGraphOpen ? "block" : "hidden"}>
                        {/* <Spectrum 
                                                audioRef={audioPlayerRef}
                                                mediaElementSourceRef={mediaElementSourceRef}
                                                audioContextRef={audioContextRef}
                                                isPlaying={isPlaying}
                                            /> */}
                        {isEQGraphOpen && (
                          <AudioSpectrum
                            audioContext={audioContextRef.current}
                            audioSource={mediaElementSourceRef.current}
                            isPlaying={isPlaying}
                          />
                        )}
                      </div>
                    )}
                  </NewAudioWaveTrack>
                </div>
              );
            })}
        </div>
        <AudioPlayerController
          isAboutVisible={isAboutVisible}
          audioIsPlaying={isPlaying}
          currentTime={currentTime}
          volume={volume}
          duration={duration}
          onNextClick={handleNextBtn}
          onPrevClick={handlePrevBtn}
          onPlayClick={togglePlayback}
          onVolumeChange={handleVolumeChange}
          onSeekTo={handleSeekClick}
          onAboutClick={toggleAbout}
        />
      </PlayerContainer>
    </>
  );
}
