import React from "react";

export default function Button({ onClick, className, children }) {
  return (
    <button
			onClick={onClick}
      className={`${className} hover:bg-grey-500 active:bg-grey-400 p-2 rounded-md`}
    >
      {children}
    </button>
  );
}
