import { useEffect, useState, useRef } from "react";

import AudioSpectrum from "../AudioSpectrum/AudioSpectrum";
import WaveformData from "waveform-data";
import WaveCanvas from "./WaveCanvas";
// import { Tooltip } from 'flowbite-react';

export default function NewAudioWaveTrack({ audio, trackIndex, currentTrackIndex, wavePosition, onClickHandler, children }) {
	
	const handleClickEvent = () => {
		onClickHandler(trackIndex);
	  };

	const [waveData, setWaveData] = useState(null);
	
	useEffect(() => {
		// console.log('audio',audio)
		const name = audio.filename;
		const filename = name.replace(/\.[^/.]+$/, "") || name;
		console.log(filename)
		fetch(`/waveform/${filename}.json`)
			.then((response) => response.json())
			.then((json) => WaveformData.create(json))
			.then((waveform) => {
				// console.log(waveform);
				// console.log(`Waveform has ${waveform.channels} channels`);
				// console.log(`Waveform has length ${waveform.length} points`);
				setWaveData(waveform);
			});
	}, []);



	return (
		<div
			onClick={handleClickEvent}
			className="flex flex-col px-2 py-2 rounded-lg bg-gray-200 my-1"
		>
			<div className="flex">
				<div className="flex-1">
					<h2>{audio.filename}</h2>
				</div>
				<div className="justify-self-end">
					{/* <Tooltip content={audio.description} id="tooltip" placement="left" className="w-48 bg-black">
                        <InformationCircleIcon className="h-4 w-4" />
                    </Tooltip> */}
				</div>
			</div>
			{/* <WaveCanvas waveform={waveData} color={"#7a93ac"} progress={(player.currentTrackIndex === Number(audio.id) ? player.progress : 0)} /> */}
			<WaveCanvas waveform={waveData} color={"#7a93ac"} progress={(currentTrackIndex === Number(audio.id) ? wavePosition : 0)} />
			{children}
			{/* {player.EQ && player.currentTrackIndex === Number(audio.id) && (
				<AudioSpectrum audioContext={audCtx} audioSource={medSrc} />
			)} */}
		</div>
	);
}