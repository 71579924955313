import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import AudioCompare from './routes/AudioCompare/AudioCompare'
import EffectPlayer from './routes/EffectPlayer/EffectPlayer';
import PEQPlayer from './routes/PEQPlayer/PEQPlayer';
import StepSequencer from './routes/StepSequencer/StepSequencer';
import MediaPlayer from './routes/MediaPlayer/MediaPlayer';

import './font.css';
import './App.css';

function Root() {
  // const mediaSource = 'https://www.youtube.com/watch?v=5kNNMVbcLlE'; // Replace with your actual media source

  return (
    <div className="flex flex-col justify-center items-center h-screen gap-1">
      <p className="mb-2 font-bold text-lg">Examples for testing only</p>
      <h2>Audio Compare with id 1</h2>
      <p className="mb-3"><a href="/audio-compare/1" className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white">/audio-compare/1</a></p>
      <h2>Effect Player Compare with type delay</h2>
      <p className="mb-3"><a href="/effect-player/delay" className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white">/effect-player/delay</a></p>
      <h2>PEQ Player with id 1</h2>
      <p className="mb-3"><a href="/peq-player/1" className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white">/peq-player/1</a></p> 
      <h2>Step Sequencer</h2>
      <p className="mb-3"><a href="/step-sequencer" className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white">/step-sequencer</a></p>
      <h2>Step Sequencer</h2>
      <p className="mb-3"><a href="/MediaPlayer/Afloat_Beat.wav" className="py-2 rounded-lg px-3 bg-grey-200 hover:bg-grey-100 text-white">/MediaPlayer/Afloat_Beat.wav</a></p> 

      <p className="mb-3">V0.0.5 Beta 20240614</p>
    </div>
  
  );
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/step-sequencer",
      element: <StepSequencer />
    },
    {
      path: "/peq-player/:id",
      element: <PEQPlayer />
    },
    {
      path: "/effect-player/:id",
      element:<EffectPlayer/>,
    },
    {
      path: "/effect-player",
      element:<Root/>,
    },
    {
      path: "/audio-compare/:id",
      element:<AudioCompare/>,
    },
    {
      path: "/audio-compare",
      element:<Root/>,
    },
    {
      path: "/MediaPlayer/:name",
      element: <MediaPlayer  />
      ,
    },
    {
      path: "",
      element:<Root />
    },
  ]);
  
  return (
    <RouterProvider router={router} />
  );
}

export default App;
