import React from "react";

export default function VerticalSlider({
  className,
  min,
  max,
  value,
  step,
  onChange,
}) {
  return (
    <div className={`${className} flex items-center rotate-[270deg] w-20 absolute -translate-x-1/2 -translate-y-1/2`}>
      <input
        className="volume-slider w-full h-3 rounded-lg overflow-hidden appearance-none bg-gray-400"
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={onChange}
      />
    </div>
  );
}
