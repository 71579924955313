import axios from "axios";
const API_BASE_URL = "https://embed.rilium.com/api";
const apiVersion = "v1";

export const getAudioCompareData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/audio-compare/${id}`);
};

export const getEffectPlayerData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/effect-player/${id}`);
};

export const getPEQPlayerData = (id) => {
  return axios.get(`${API_BASE_URL}/${apiVersion}/peq/${id}`);
};
