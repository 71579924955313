import { useState, useEffect } from 'react';

export function useFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full screen
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullScreen(true))
        .catch((e) => {
          console.error(`Failed to enter full-screen mode: ${e.message}`);
        });
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document
          .exitFullscreen()
          .then(() => setIsFullScreen(false))
          .catch((e) => {
            console.error(`Failed to exit full-screen mode: ${e.message}`);
          });
      }
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        setIsFullScreen(false);
      }
    };

    if (document.addEventListener) {
      document.addEventListener("webkitfullscreenchange", exitHandler, false);
      document.addEventListener("mozfullscreenchange", exitHandler, false);
      document.addEventListener("fullscreenchange", exitHandler, false);
      document.addEventListener("MSFullscreenChange", exitHandler, false);
    }

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  return { isFullScreen, toggleFullScreen };
}