import React from "react";

export default function Slider({ min, max, value, onChange }) {
  return (
    <input
      className={` w-full cursor-pointer disabled:opacity-50 disabled:pointer-events-none focus:outline-none volume-slider rounded-lg overflow-hidden appearance-none bg-gray-400 `}
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={onChange}
    />
  );
}
